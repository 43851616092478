* {
  font-family: 'Poppins', sans-serif !important;
}

body {
  height: 100vh;
  background: #fdfdff;
}

.visible {
  display: inherit;
}

.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
}

p {
  margin-top: 10px !important;
}

h3,
h5 {
  text-align: center;
  display: inline-block;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-dismissible {
  padding-right: 2rem;
}

.alert-dismissible .close {
  position: absolute;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
  background-color: transparent;
  border: 0;
  float: right;
  font-size: 1.40625rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: none;
  opacity: 0.5;
}

.alert-danger {
  color: #333333;
  background-color: #ff9e9e;
}

.alert-success {
  color: #333333;
  background-color: #9cff90;
}

.alert-warning {
  color: #333333;
  background-color: #f79745;
}

.card-container {
  max-width: 400px;
  min-width: 350px;
  margin-top: 50px;
}

.main-container {
  margin: 2rem auto;
  max-width: 900px;
}

.fa-small {
  font-size: 16px !important;
}

.btn-float {
  float: left;
  margin-right: 10px;
}

.img-small {
  width: 180px;
}

.section-thin {
  margin: 0.75rem auto;
}

.bottom-link {
  margin-top: 1.25rem;
}

.form-control {
  padding-left: 7px !important;
  display: inline-block !important;
  margin: auto;
}

.form-group {
  width: 90% !important;
  margin: 0.75rem auto;
}

.tickets-action-bar-wrapper .form-group {
  width: 100% !important;
  margin: 0;
  align-self: center;
}

.form-text {
  text-align: right;
  padding-right: 5px;
}

.bottom-margin {
  margin-bottom: 1.25rem;
}

.top-margin {
  margin-top: 1.25rem;
}

.validation-text {
  font-size: small;
  text-align: left;
  padding-left: 23px;
  color: #cc0000;
  width: 90%;
}

.btn.red {
  background-color: #de5246 !important;
  font-weight: 400;
  border-color: transparent;
}

.shadow-card {
  border-color: #2d2d2d12;
  box-shadow: 2px 10px 25px -5px rgba(0, 0, 0, 0.07),
    5px 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.subtitle {
  text-align: left;
  font-size: medium;
  font-weight: 700;
}

.delete-form {
  display: inline-block;
  margin: 0;
}

.icon-btn {
  padding: 1px 4px;
  display: inline-block;
  color: #797979;
  margin: 0 10px 0 0;
  font-size: medium;
  border: 2px solid #c2c2c2;
  background-color: #dadada;
  color: #2e3b48;
  border-radius: 5px;
  transition: color 0.5s ease, border 0.5s ease, background-color 0.5s ease;
}

.icon-btn.tickets {
  margin: 0 10px;
  border-radius: 20px;
  border: 2px solid transparent;
  background-color: transparent;
  padding: 0;
  height: 20px;
}

.icon-btn.show-dates {
  margin: 0;
  border-radius: 20px;
  border: 2px solid transparent;
  background-color: transparent;
  padding: 0;
  height: 20px;
  overflow: hidden;
}

.icon-btn.show-dates svg {
  vertical-align: 1px;
}

.fa-plus-circle,
.fa-minus-circle {
  transform: translateY(-4px);
}

.icon-btn.edit {
  border: 2px solid #aacef359;
  background-color: #d0e5af59;
  color: #5d7084;
  border-radius: 5px;
}

.circular-btn .fa-edit {
  transform: translate(1.5px, -0.3px);
}

.editable-title-wrapper .fa-pen,
.editable-ticket-history-wrapper .fa-pen {
  cursor: pointer;
  margin: 0 10px 0 0;
}

.icon-btn.delete {
  /* border: 2px solid #e6b2b259;
  background-color: #ffd0d059; */
  color: #845d5d;
  border-radius: 5px;
}

.icon-btn:hover,
.icon-btn:active,
.icon-btn:focus {
  border: 2px solid #8c8b8b;
  background-color: #b1b1b1;
  color: #000000;
}

.icon-btn:hover.delete,
.icon-btn:active.delete,
.icon-btn:focus.delete {
  /* border: 2px solid #e4999cd5;
  background-color: #f7adafd5; */
  color: #5f4243;
}

.icon-btn:hover.tickets,
.icon-btn:active.tickets,
.icon-btn:focus.tickets {
  border: 2px solid #dadada;
  background-color: #eeeeee;
  color: #333333;
}

.icon-btn:hover.edit,
.icon-btn:active.edit,
.icon-btn:focus.edit {
  /* border: 2px solid #83acd8d5;
  background-color: #add5ffd5; */
  color: #576c82;
}

.icon-btn:focus,
.icon-btn:focus-visible,
.icon-btn:focus-within {
  outline: 0;
  box-shadow: none;
  border-color: #333333;
}

.icon-btn:focus.delete,
.icon-btn:focus-visible.delete,
.icon-btn:focus-within.delete {
  /* border: 2px solid #771414d5;
  background-color: #f7adafd5; */
  color: #5f4243;
}

.icon-btn:focus.tickets,
.icon-btn:focus-visible.tickets,
.icon-btn:focus-within.tickets {
  border: 2px solid transparent;
  color: #333333;
  outline: 1px solid #7c7cff;
}

.icon-btn:focus.edit,
.icon-btn:focus-visible.edit,
.icon-btn:focus-within.edit {
  /* border: 2px solid #1e3a58d5;
  background-color: #add5ffd5; */
  color: #2e3b48;
}

.ticket-counter {
  margin: auto;
  display: flex;
  align-items: center;
}
.action-buttons a {
  display: inline-block;
}

label {
  font-size: small;
  margin: 0;
}

.card {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.table-title {
  margin-bottom: 20px;
}

.table-title,
.todays-show-title {
  display: block;
  font-size: xx-large;
  text-align: center;
  padding-left: 7px;
  width: 100%;
}

.todays-show-title {
  margin: -32px 0 7px 0;
}

.date {
  display: inline;
  float: right;
  margin-left: auto;
  margin-right: 0;
}

.inline-check {
  display: inline;
}

.inline-check .form-check {
  width: 250px;
  margin: 0 10px;
  display: inline;
  vertical-align: 4px;
}

.inline-check .form-check .form-check-input {
  position: unset;
  margin-right: 5px;
  vertical-align: -2px;
}

.tickets-action-bar-wrapper .inline-check {
  padding-left: 7px;
}

.circular-btn {
  vertical-align: baseline;
  border-radius: 2rem;
  border: 3px solid #3e3e3e;
  background-color: #3e3e3e;
  color: white;
  font-weight: bold;
  font-size: medium;
  padding: 4px 6px;
  transition-property: color, background-color, border, transform, filter;
  transition-duration: 0.1s, 0.1s, 0.1s, 0.3s, 0.2s;
  transition-delay: 0s, 0s, 0s, 0s, 0.1s;
}

.card-header,
.list-group-item {
  font-size: 1.063rem;
  padding: 8px 15px;
  border: 0;
  background-color: #f4f4f4;
}

.modal-body .list-group .list-group-item {
  background-color: transparent;
}

.users-table-header .search-bar {
  position: absolute;
  top: 0;
  right: 20px;
}

.tickets-table-header .plus-btn,
.tickets-table-header .refresh-btn {
  margin: 7.5px 11px;
}

.circular-btn:hover,
.circular-btn:focus,
.circular-btn:focus-within,
.circular-btn:focus-visible,
.circular-btn:active {
  filter: drop-shadow(5px 2px #333333);
  color: #333333;
  background-color: white;
  border: 3px solid #333333;
  transform: translate(-5px, -2px);
}

.circular-btn-icon {
  display: inline-block;
}

.refresh-btn:hover .circular-btn-icon {
  animation: rotation 0.45s ease-in-out !important;
}

.circular-btn:hover .circular-btn-icon {
  animation: scale 0.7s ease-in-out;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes scale {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: none;
  cursor: pointer;
  vertical-align: revert;
  margin: 0 0 0 5px;
}

.button-as-link:hover {
  color: #333333;
}

.stateToDebug {
  display: inline-block;
}

.card-container.debug {
  margin: 0;
  position: absolute;
  display: inline-block;
}

.invalid-feedback {
  color: #9e0000 !important;
}

.tickets-row,
.tickets-row-title {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr) 0.05fr;
}

.tickets-row-title {
  border-radius: 10px;
}

.user-info-row,
.archived-user-info-row,
.user-info-row-title {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1.5fr 1fr 0.5fr;
}

.user-info-row-title,
.tickets-row-title {
  background-color: transparent;
  padding: 5px 15px;
  border: 0;
}

.user-info-row-title-group,
.tickets-row-title-group {
  background-color: #333333;
  border-radius: 5px 5px 0 0;
  color: white;
  font-weight: bold;
  font-size: large;
}

.list-group-item:last-child.user-info-row,
.list-group-item:last-child.archived-user-info-row {
  border-radius: 0 0 6px 6px;
}

.list-group-item:first-child.user-info-row,
.list-group-item:first-child.archived-user-info-row {
  border-radius: 0;
}

.previous-shows {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr) 0.05fr;
  color: #595959;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .user-info-row,
  .archived-user-info-row,
  .user-info-row-title {
    grid-template-columns: repeat(2, 1fr) 0.5fr;
  }

  .tickets-row,
  .tickets-row-title {
    grid-template-columns: repeat(2, 1fr) 0.05fr;
  }

  .text-center {
    margin: 0 10px 0 4px;
  }

  .hide-if-small {
    display: none;
  }

  .todays-show-subtitle {
    display: block;
  }
}

.tickets-row:hover {
  cursor: pointer;
}

.users-table-header {
  margin-bottom: 5px;
}

.accordion {
  border: 0;
}

.accordion .card {
  border: 0;
}

.accordion .card:first-of-type {
  border-radius: 0;
}

.accordion .card:last-of-type {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion .card:nth-of-type(odd),
.list-group .user-info-row:nth-of-type(odd) {
  background-color: #ebf6fc;
}

.accordion .card:nth-of-type(even),
.list-group .user-info-row:nth-of-type(even) {
  background-color: #d9e8f1;
}

.list-group .archived-user-info-row,
.archived-tickets-row,
.no-tickets-row {
  background-color: #efefef !important;
}

.accordion .card .card-header {
  background-color: transparent;
}

.list-group {
  text-align: left;
  font-size: inherit;
}

.badge {
  border: 2px #d4d4d4 solid;
  font-weight: bolder;
  font-size: 100%;
  width: 40px;
  padding: 7px 5px;
  border-radius: 3px;
}

.icon-btn.btn-primary.disabled,
.icon-btn.btn-primary:disabled {
  color: #747474;
  background-color: transparent;
  border-color: transparent;
}

.circular-btn.btn-primary.disabled,
.circular-btn.btn-primary:disabled,
.circular-btn:hover.btn-primary.disabled,
.circular-btn:focus.btn-primary.disabled,
.circular-btn:focus-within.btn-primary.disabled,
.circular-btn:focus-visible.btn-primary.disabled,
.circular-btn:active.btn-primary.disabled {
  background-color: #747474;
  border-color: transparent;
  filter: none;
  transform: none;
  color: white;
}

.user-info-row-item {
  border-right: lightgray;
}

.user-info-row-item:last-of-type {
  border-right: none;
}

.muted.user-info-row-item,
.muted.form-control {
  font-style: italic;
  color: #696969;
}

.navbar.bg-dark {
  background-color: #333333 !important;
}

.card-icon {
  margin-right: 10px;
}

.search-bar {
  display: inline-block;
  width: 225px;
}

.tickets-table-header .search-bar {
  width: 100%;
}

.search-bar .form-control {
  padding: 0.3rem 1.3rem 0.3rem 0.9rem !important;
  width: 100%;
  border: 4px solid #333333;
  border-radius: 30px;
  vertical-align: baseline;
}

.show-name .form-control {
  padding: 0.3rem 1.3rem 0.3rem 1.3rem !important;
  text-align: center;
  width: 100%;
  border: 4px solid #333333;
  border-radius: 30px;
  vertical-align: baseline;
}

::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #000000;
  opacity: 0.6 !important; /* for older chrome versions. may no longer apply. */
}

:-moz-placeholder,
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
  opacity: 0.8 !important;
}

::-moz-placeholder,
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
  opacity: 0.8 !important;
}

:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #000000;
}

.navbar-dark .navbar-toggler {
  border: 3px solid white;
  transition: all 0.5s;
  padding: 0.1rem 0.35rem;
}

.navbar-toggler:hover {
  background-color: white;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  transition: all 0.5s;
}

.navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.sorting-buttons {
  display: inline;
}

.sorting-buttons .btn-primary {
  padding: 0;
  background-color: transparent;
  border: 0;
  display: inline-grid;
  display: inline-grid;
  margin: 0 0 3px 0;
}

.sorting-buttons .btn-primary .fa-fw {
  margin: -3px 0;
}
.sorting-buttons .btn-primary .fa-fw {
  color: #a9a9a9;
}

.sorting-buttons .btn-primary .fa-fw.sorted-by {
  color: white;
}

.sorting-buttons .btn-primary:hover,
.sorting-buttons .btn-primary:focus,
.sorting-buttons .btn-primary:focus-visible,
.sorting-buttons .btn-primary:focus-within,
.sorting-buttons .btn-primary:active,
.sorting-buttons .btn-primary:visited {
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
}

.user-title-row {
  display: grid;
  grid-template-columns: 1fr;
}

.user-title-row .form-group {
  margin: 0;
}

.margin-left {
  margin-left: 10px;
}

.react-calendar {
  margin: auto;
  display: inline;
}

.calendar-action-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
  row-gap: 5px;
  max-height: max-content;
  min-height: max-content;
  justify-items: center;
  align-items: center;
  margin: 10px 0;
}

.black-and-white-primary-btn {
  color: white;
  background-color: #333333;
  transition: all 0.5s;
  border: 4px #333333 solid;
  font-size: large;
  border-radius: 50px;
  max-width: 400px;
  margin: 0 auto;
  padding: 0.3rem 1.3rem;
  transition-property: color, background-color, border, transform, filter;
  transition-duration: 0.1s, 0.1s, 0.1s, 0.3s, 0.2s;
  transition-delay: 0s, 0s, 0s, 0s, 0.1s;
  width: 120px;
}

.black-and-white-primary-btn:hover,
.black-and-white-primary-btn:focus,
.black-and-white-primary-btn:focus-visible,
.black-and-white-primary-btn:focus-within,
.black-and-white-primary-btn:active {
  color: #333333;
  background-color: white;
  border: 4px#333333 solid;
  outline: 0;
  box-shadow: none;
  transform: translate(-5px, -2px);
  filter: drop-shadow(5px 2px#333333);
}

.black-and-white-primary-btn.disabled,
.black-and-white-primary-btn:disabled,
.black-and-white-primary-btn:hover.btn-primary.disabled,
.black-and-white-primary-btn:focus.btn-primary.disabled,
.black-and-white-primary-btn:focus-within.btn-primary.disabled,
.black-and-white-primary-btn:focus-visible.btn-primary.disabled,
.black-and-white-primary-btn:active.btn-primary.disabled {
  background-color: #747474;
  border-color: transparent;
  filter: none;
  transform: none;
  color: white;
}

.react-calendar__tile--active,
.react-calendar__tile--active:hover,
.react-calendar__tile--active:focus,
.react-calendar__tile--active:focus-visible,
.react-calendar__tile--active:focus-within,
.react-calendar__tile--active:active {
  background-color: transparent !important;
  color: #333333 !important;
}

.show-name .form-control,
.show-name .form-control:hover,
.show-name .form-control:focus,
.show-name .form-control:focus-visible,
.show-name .form-control:focus-within,
.show-name .form-control:active {
  font-size: large;
  outline: 0;
  box-shadow: none;
  margin: 0 !important;
}

.active-show-tile,
.active-show-tile:hover,
.active-show-tile:focus,
.active-show-tile:focus-within,
.active-show-tile:focus-visible,
.active-show-tile:active {
  max-height: 50px;
  min-height: 50px;
  background: #d1eeff !important;
  background-color: #d1eeff !important;
  color: #333333 !important;
}

.muted-calendar-tile,
.muted-calendar-tile:hover,
.muted-calendar-tile:focus,
.muted-calendar-tile:focus-within,
.muted-calendar-tile:focus-visible,
.muted-calendar-tile:active {
  max-height: 50px;
  min-height: 50px;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}

.regular-calendar-tile:hover,
.regular-calendar-tile:focus,
.regular-calendar-tile:focus-within,
.regular-calendar-tile:focus-visible,
.regular-calendar-tile:active {
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}

.active-calendar-tile:hover,
.active-calendar-tile:focus,
.active-calendar-tile:focus-within,
.active-calendar-tile:focus-visible,
.active-calendar-tile:active {
  background-color: #d1eeff !important;
  color: #333333 !important;
}

.react-calendar__tile--now {
  background-color: #ebf6fc !important;
}

.saved-active-tile.matinee-show-tile {
  background-color: #daecff33 !important;
  color: #333333 !important;
}

.saved-active-tile.evening-show-tile {
  background-color: #0d325933 !important;
  color: #333333 !important;
}

.saved-active-tile.both-show-tile {
  background-color: #627c9433 !important;
  color: #333333 !important;
}

.saved-tile abbr {
  color: black !important;
}

.saved-tile.selected-show-tile abbr {
  color: inherit !important;
}

.selected-show-tile.evening-show-tile {
  background-color: #0d3259 !important;
  color: white !important;
}

.selected-show-tile.evening-show-tile:hover,
.selected-show-tile.evening-show-tile:focus,
.selected-show-tile.evening-show-tile:focus-within,
.selected-show-tile.evening-show-tile:focus-visible,
.selected-show-tile.evening-show-tile:active {
  background-color: #0d3259 !important;
  color: white !important;
}

.selected-show-tile.matinee-show-tile {
  background-color: #daecff !important;
  color: #333333 !important;
}

.selected-show-tile.matinee-show-tile:hover,
.selected-show-tile.matinee-show-tile:focus,
.selected-show-tile.matinee-show-tile:focus-within,
.selected-show-tile.matinee-show-tile:focus-visible,
.selected-show-tile.matinee-show-tile:active {
  background-color: #daecff !important;
  color: #333333 !important;
}

.selected-show-tile.both-show-tile {
  background-color: #627c94 !important;
  color: white !important;
}

.selected-show-tile.both-show-tile:hover,
.selected-show-tile.both-show-tile:focus,
.selected-show-tile.both-show-tile:focus-within,
.selected-show-tile.both-show-tile:focus-visible,
.selected-show-tile.both-show-tile:active {
  background-color: #627c94 !important;
  color: white !important;
}

.selected-show-tile,
.saved-tile,
.saved-active-tile,
.regular-calendar-tile,
.active-calendar-tile,
.active-show-tile {
  display: grid !important;
  padding: 0 !important;
  grid-template-areas: 'd b c' 'd a c' 'd e c';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  max-height: 50px;
  min-height: 50px;
}

.selected-show-tile abbr,
.saved-tile abbr,
.saved-active-tile abbr,
.regular-calendar-tile abbr,
.active-calendar-tile abbr,
.active-show-tile abbr {
  grid-area: a;
  opacity: 1;
}

.selected-show-tile .last-col,
.saved-tile .last-col,
.saved-active-tile .last-col,
.regular-calendar-tile .last-col,
.active-calendar-tile .last-col,
.active-show-tile .last-col {
  grid-area: c;
  font-size: small;
  font-weight: bolder;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  height: 100%;
}

.selected-show-tile .last-col .mat-label,
.saved-tile .last-col .mat-label,
.saved-active-tile .last-col .mat-label,
.regular-calendar-tile .last-col .mat-label,
.active-calendar-tile .last-col .mat-label,
.active-show-tile .last-col .mat-label {
  grid-row: 1;
  font-size: small;
}

.selected-show-tile.both-show-tile .last-col .mat-label,
.selected-show-tile.both-show-tile .last-col .eve-label,
.selected-show-tile.evening-show-tile .last-col .mat-label,
.selected-show-tile.evening-show-tile .last-col .eve-label {
  color: white !important;
}

.selected-show-tile .last-col .eve-label,
.saved-tile .last-col .eve-label,
.saved-active-tile .last-col .eve-label,
.regular-calendar-tile .last-col .eve-label,
.active-calendar-tile .last-col .eve-label,
.active-show-tile .last-col .eve-label {
  grid-row: 3;
  font-size: small;
}

.selected-show-tile .last-col .eve-label,
.regular-calendar-tile .last-col .eve-label,
.active-calendar-tile .last-col .eve-label {
  color: white !important;
}

.active-show-tile .last-col .eve-label,
.selected-show-tile.matinee-show-tile .last-col .eve-label,
.saved-active-tile .last-col .mat-label,
.saved-active-tile .last-col .eve-label {
  color: #333333 !important;
}

.saved-tile.evening-show-tile .last-col .mat-label,
.saved-tile.both-show-tile .last-col .mat-label,
.saved-tile.matinee-show-tile .last-col .mat-label,
.saved-tile.evening-show-tile .last-col .eve-label,
.saved-tile.both-show-tile .last-col .eve-label,
.saved-tile.matinee-show-tile .last-col .eve-label {
  color: black !important;
}

.saved-show-block {
  padding: 0;
}

.proposed-show-row,
.saved-show-row {
  display: grid;
  grid-template-columns: 1fr 1fr 0.05fr;
  padding: 8px 5px;
}

.proposed-show-dates {
  grid-column: 1;
  text-align: center;
  align-self: center;
}

.current-proposed-date .proposed-show-dates {
  padding-right: 9px;
}

.show-dates.list-group {
  border-radius: 0 0 13px 13px !important;
}

.proposed-show-times {
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 2px solid#333333;
  width: 100px;
  height: 30px;
}

.legend {
  grid-row: 2;
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5px;
  align-self: flex-end;
}

.time-box {
  text-align: center;
}

.legend .time-box {
  border: 0;
  border-radius: 20px;
  width: 50px;
  margin: auto;
  font-size: small;
}

.matinee-time {
  background-color: #daecff;
  color: #333333 !important;
}

.proposed-show-times .matinee-time {
  border-right: 1px solid#333333;
}

.evening-time {
  background-color: #0d3259;
  color: white !important;
}

.proposed-show-times .both-time {
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}

.proposed-show-times .evening-time {
  border-left: 1px solid#333333;
}

.both-time {
  background-color: #627c94 !important;
  color: white !important;
}

.show-dates {
  max-height: 400px;
  overflow-y: scroll;
}

.red {
  color: red;
}

.saved-tile.matinee-show-tile {
  background-color: #daecff5a !important;
  color: #333333 !important;
}

.saved-tile.evening-show-tile {
  background-color: #0d32595a !important;
  color: #333333 !important;
}

.saved-tile.both-show-tile {
  background-color: #627c945a !important;
  color: #333333 !important;
}

.current-proposed-date {
  background-color: #cfcfd0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8px 28px 8px 11px;
  border-radius: 0px !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: #f4f4f4 !important;
  color: grey !important;
}

.saved-show-block .title-change .form-control {
  text-align: center;
  width: 100%;
  padding: 0 0 0 27px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  background-color: transparent;
  outline: 0 !important;
  height: 2rem;
  border: 0;
}

.previous-shows .title-change {
  max-width: fit-content;
}

.previous-shows .icon-btn {
  grid-column: 4;
}

.show-dates-title {
  background-color: #333333 !important;
  color: white;
  border-radius: 10px 10px 0 0 !important;
  font-size: x-large;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent !important;
  color: #333333 !important;
  font-size: larger;
}

.react-calendar {
  border: 0 !important;
}

.legend-label {
  text-align: center;
  font-size: small;
}

.instructions {
  margin: 10px auto;
  font-size: medium;
  grid-column: 1;
  grid-row: 2;
  color: #f1f1f1;
  padding: 0;
}

.dashboard-section,
.schedule-card {
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
}

.dashboard-section {
  border-radius: 20px;
  padding: 20px 20px 40px;
  margin: 20px 0;
}

.schedule-card {
  margin: 10px auto;
  border: 2px solid #333333;
  border-radius: 13px;
  height: 430px;
  background-color: #f7f7f7;
}

.saved-show-row:hover {
  background-color: #e2e2e2;
}

.focused-show-row,
.proposed-show-row:hover {
  background-color: #d5d5d5 !important;
  border-radius: 0 !important;
}

.tooltip-inner {
  font-size: medium;
  max-width: 400px;
  background-color: white;
  color: #333;
}

.bs-tooltip-top .arrow::before {
  border-top-color: white;
}

.tooltip.show {
  opacity: 1 !important;
}

.editable-title-wrapper:hover,
.editable-title-wrapper:focus,
.editable-title-wrapper:focus-visible,
.editable-title-wrapper:focus-within,
.editable-title-wrapper:active {
  cursor: text;
  background-color: #b4e0fb;
  outline: 0;
  box-shadow: none;
  transition: all 0.5s;
}

.saved-show-block .title-change .form-control-wrapper::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 3px;
  right: 27px;
  pointer-events: none;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 80%,
    rgb(235 246 252) 94%
  );
  width: 100%;
  height: 2rem;
  transition: all 0.5s;
}

.editable-title-wrapper:hover .form-control-wrapper::after,
.editable-title-wrapper:focus .form-control-wrapper::after,
.editable-title-wrapper:focus-visible .form-control-wrapper::after,
.editable-title-wrapper:focus-within .form-control-wrapper::after,
.editable-title-wrapper:active .form-control-wrapper::after {
  opacity: 0;
  transition: all 0.2s;
}

.title-change.input-group:hover,
.title-change.input-group:focus,
.title-change.input-group:focus-visible,
.title-change.input-group:focus-within,
.title-change.input-group:active,
.title-change.input-group:hover .form-control,
.title-change.input-group:focus .form-control,
.title-change.input-group:focus-visible .form-control,
.title-change.input-group:focus-within .form-control,
.title-change.input-group:active .form-control {
  outline: 0;
  border-color: transparent;
  box-shadow: none;
}

.editable-title-wrapper {
  background-color: #ebf6fc;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  width: 100%;
}

.editable-title-wrapper,
.editable-ticket-history-wrapper {
  display: grid;
  grid-template-columns: 1fr 0.05fr;
  align-items: center;
  transition: all 0.5s;
}

.editable-ticket-history-wrapper .form-control {
  background-color: transparent;
  border: none;
}

.show-selection {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.show-selection label {
  font-size: large;
  width: 75px;
}

.show-selection .form-control {
  margin: 0 0 0 15px !important;
}

.show-selection.form-group {
  width: 100% !important;
  padding: 0 0 0 15px;
  margin-top: 0 !important;
}

.set-todays-show-wrapper {
  align-items: center;
  width: 75%;
  padding: 0 15px;
  margin: auto;
}

.set-todays-show-wrapper .or {
  grid-column: 1/-1;
  text-align: center;
}

.tickets-action-bar-wrapper {
  display: grid;
  grid-template-columns: 1fr 60px 60px;
}

.shows-dropdown,
.shows-input {
  padding: 0 12px !important;
  border: 3px solid black;
  font-weight: bolder;
}

.shows-input {
  border-radius: 30px !important;
}

.todays-show-subtitle {
  font-size: larger;
  text-align: center;
  width: 100%;
  color: #737373;
  font-style: italic;
  margin-bottom: 7px;
}

.shows-input-btn:hover {
  background-color: white !important;
  color: #333333 !important;
  border: 2px black solid;
  transform: translate(-5px, -2px);
  box-shadow: 5px 2px black;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.accordion > .card {
  overflow: visible;
}

.date-wrapper {
  padding-left: 7px;
}

.collapse.show .list-group {
  background-color: #f4f4f4;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-wrapper input {
  border: 1px solid #e8e8e8;
  height: calc(1.5em + 1rem + 2px);
  padding-left: 7px;
}

@media only screen and (max-width: 991px) {
  .saved-show-block .title-change .form-control-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 3px;
    right: 40px;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 80%,
      rgb(235 246 252) 94%
    );
    width: 100%;
    height: 2rem;
    transition: all 0.5s;
  }
}

@media only screen and (min-width: 991px) {
  .purchase-flexpass {
    background-color: white;
    color: #333333 !important;
    border: 3px solid transparent;
    border-radius: 30px !important;
    margin-right: 20px;
    transition: all 0.5s;
    padding: 0 7px;
  }

  .purchase-flexpass:hover {
    background-color: #333333;
    color: white !important;
    border: 3px white solid;
  }

  .purchase-flexpass-item {
    padding-top: 6px;
  }
}

.login-purchase-flexpass {
  display: block;
  margin: 10px auto;
  color: white;
  background-color: #333333;
  border-radius: 30px;
  width: 200px;
  padding: 5px 7px;
  border: 3px solid black;
  transition: all 0.5s;
}

.login-purchase-flexpass:hover,
.login-purchase-flexpass:focus,
.login-purchase-flexpass:focus-visible,
.login-purchase-flexpass:focus-within,
.login-purchase-flexpass:active {
  transform: translate(-5px, -2px);
  box-shadow: 5px 2px black;
  color: #333333;
  background-color: white;
  border: 3px solid black;
  text-decoration: none;
}

.google-login-button {
  color: white;
  background-color: #ea4334;
  border-radius: 30px;
  border-color: #ea4334;
}

.google-login-button:hover {
  color: white;
  background-color: #b3362b;
  border-radius: 30px;
  border-color: #ea4334;
}

.login-button {
  color: white;
  border-radius: 30px;
}

.login-button:hover {
  color: white;
  border-radius: 30px;
}

.current-user {
  background-color: #0d325999 !important;
  color: white !important;
}

.display-none {
  display: none;
}
